/* eslint-disable */
import { getUser, getCompany } from '@/services/User'
import { includes, cloneDeep } from 'lodash'
import { getAuth } from 'firebase/auth'
import axios from 'axios'

export const state = () => ({
  user: {
    name: '',
    email: '',
    dependency: '',
    public_address: '',
    first_name: '',
    last_name: '',
    last_name2: '',
    birthday: '',
    rfc: '',
    celphone: '',
    telphone: '',
    // Campos MTY
    fotografia: '',
    curp: '',
    biografia: '',
    genero: '',
    // ********************************
    // CP es un campo compartido pa todas y pa ViLo
    cp: '',
    // ********************************
    estado: '',
    municipio: '',
    colonia: '',
    // calle y num_ext es un campo compartido pa todas y pa ViLo
    calle: '',
    num_ext: '',
    // ********************************
    num_int: '',
    referencias: '',
    taxProfile: '',
    activated: false,
    created: '',
    uid: '',
    totalDocumentsFinals: 0,
    levelUpdated: null,
    token: null,
    role: 'user',
    auxRole: 'citizen',
    availableModules: [],
    typeProfile: 'Ciudadano',
    // Campos ViLo
    deptounidad: '',
    dirección: '',
    piso: '',
    sexo: '',
    torre: '',
    localidad: '',
    // ********************************
    levelCidi: 1,
    usersIHavePermissions: [],
    companiesIHavePermissions: [],
    involvedModules: [],
  },
  currentUrl: '',
})

export const mutations = {
  SET_ID_TOKEN(state, idToken) {
    state.idToken = idToken
  },
  SET_USER_UID(state, uid) {
    state.userUid = uid
  },
  SET_USER: (state, user) => {
    if (user.company || user.isProfileChange) {
      const cloneUser = cloneDeep(user)
      delete cloneUser.isProfileChange
      state.user = { ...cloneUser }
    } else {
      state.user.levelCidi = user.levelCidi ? user.levelCidi : 1
      state.user.type = user.type ? user.type : null
      state.user = user === {} ? { ...user } : { ...state.user, ...user }
    }

    let role = ''
    if (user.role && user.role !== undefined) {
      role = user.role
    } else if (state.user.role || state.user.role === '') {
      role = state.user.role
    }

    let auxRole = ''
    if (user.auxRole && user.auxRole !== undefined) {
      auxRole = user.auxRole
    } else if (state.user.auxRole || state.user.auxRole !== '') {
      auxRole = state.user.auxRole
    } else {
      auxRole = 'citizen'
    }

    state.user.role = role
    state.user.auxRole = auxRole

    state.user.typeProfile =
      state.user.role &&
      includes(
        ['superadmin', 'admin', 'operator', 'editor', 'communicator'],
        state.user.role
      )
        ? 'Funcionario'
        : state.user.auxRole &&
          includes(['officer', 'visualizer'], state.user.auxRole)
        ? 'Funcionario'
        : 'Ciudadano'
  },
  SET_VILO_TOKEN(state, token) {
    state.vilo_token = token
  },
  SET_TOKEN(state, token) {
    state.token = token
  },
  SET_ROLE(state, role) {
    state.user.role = role
  },
  SET_AUXROLE(state, auxRole) {
    state.user.auxRole = auxRole
  },

  SET_AVAILABLEMODULES(state, availableModules) {
    state.user.availableModules = availableModules
  },
  LOGOUT_USER(state) {
    state.user = {
      name: '',
      email: '',
      public_address: '',
      first_name: '',
      dependency: '',
      last_name: '',
      last_name2: '',
      birthday: '',
      rfc: '',
      celphone: '',
      telphone: '',
      cp: '',
      estado: '',
      municipio: '',
      colonia: '',
      calle: '',
      num_ext: '',
      num_int: '',
      referencias: '',
      taxProfile: '',
      activated: false,
      created: '',
      uid: '',
      totalDocumentsFinals: 0,
      levelUpdated: null,
      token: null,
      role: 'user',
      auxRole: 'citizen',
      availableModules: [],
      typeProfile: 'Ciudadano',
      deptounidad: '',
      dirección: '',
      piso: '',
      sexo: '',
      fotografia: '',
      curp: '',
      biografia: '',
      genero: '',
      torre: '',
      localidad: '',
      levelCidi: 1,
      useOnlyProcedurePost: false,
      usersEmpowered: [],
    }
  },
}

export const actions = {
  async on_auth_state_changed_action({ commit }, { authUser }) {
    if (authUser) {
      const tokenResult = await authUser.getIdTokenResult()
      commit('SET_TOKEN', tokenResult.token)
      commit('SET_ROLE', tokenResult.claims.role)
      // Role for new modules
      if (tokenResult.claims.auxRole) {
        commit('SET_AUXROLE', tokenResult.claims.auxRole)
      } else {
        commit('SET_AUXROLE', 'citizen')
      }
      commit('SET_AVAILABLEMODULES', tokenResult.claims.availableModules)
    }
  },
  async on_id_token_changed_action({ commit }, { authUser }) {
    if (authUser) {
      const tokenResult = await authUser.getIdTokenResult()
      commit('SET_TOKEN', tokenResult.token)
      commit('SET_ROLE', tokenResult.claims.role)
      if (tokenResult.claims.auxRole) {
        commit('SET_AUXROLE', tokenResult.claims.auxRole)
      } else {
        commit('SET_AUXROLE', 'citizen')
      }
      commit('SET_AVAILABLEMODULES', tokenResult.claims.availableModules)
    }
  },
  async fetchUser({ commit }, authData) {
    if (authData && authData.authUser) {
      const tokenResult = await authData.authUser.getIdTokenResult()
      commit('SET_ID_TOKEN', tokenResult.token)
      commit('SET_ROLE', tokenResult.claims.role)
      commit('SET_USER_UID', tokenResult.claims.role)
      //commit('SET_SAVED_ROLE', tokenResult.claims.role)
      if (tokenResult.claims.auxRole) {
        commit('SET_AUXROLE', tokenResult.claims.auxRole)
      } else {
        await axios
          .post('/users-addCitizen', {
            uid: authData.authUser.uid,
          })
          .then(async () => {
            await getAuth().currentUser?.getIdToken(true)
          })
        commit('SET_AUXROLE', 'citizen')
      }
      commit('SET_AVAILABLEMODULES', tokenResult.claims.availableModules)
      this.$fire.appCheck.activate(this.$config.appCheckSiteKey, true)
      const currentProfile = localStorage.getItem('currentProfile')
      let user = null
      if (currentProfile) {
        // If the profile is a user empowered or a company
        const localStorageUser = JSON.parse(currentProfile)
        let userFromDB = null
        if (localStorageUser.company) {
          // If the profile is a company
          userFromDB = await (
            await getCompany(this.$fire, localStorageUser.uid)
          ).data()
        } else {
          // If the profile is a user empowered
          userFromDB = await (
            await getUser(this.$fire, localStorageUser.uid)
          ).data()
        }
        // Update localStorage with database information
        if (userFromDB) {
          user = Object.assign({}, localStorageUser, userFromDB)
          localStorage.setItem('currentProfile', JSON.stringify({ ...user }))
        } else {
          user = Object.assign({}, localStorageUser)
        }
      } else {
        // If the profile is a user in session
        user = await (await getUser(this.$fire, authData.authUser.uid)).data()
      }

      if (user) {
        commit('SET_USER', {
          ...user,
        })
        //identify user in sentry
        this.$sentry.setUser({
          id: user.uid,
          email: user.email,
          username: `${user.first_name} ${user.last_name} ${
            user.last_name2 ? user.last_name2 : ''
          }`,
        })
      }
    } else {
      commit('SET_USER', {})
      this.$sentry.setUser(null)
    }
  },
  sendCode({ dispatch }, dataRecaptcha) {
    return new Promise((resolve, reject) => {
      this.$fire.auth
        .signInWithPhoneNumber(dataRecaptcha.phone, dataRecaptcha.verifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult
          resolve(confirmationResult)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  setUserData({ commit }, { data }) {
    commit('SET_USER', { ...data })
  },
}

export const getters = {
  user: (state) => {
    return state.user
  },
  publicAddress: (state) => {
    return state.user.public_address
  },
  getLevelCidi: (state) => {
    return state.user.levelCidi
  },
  levelUpdated: (state) => {
    return state.user.levelUpdated
  },
  userName(state) {
    return state.user.first_name
  },
  getFullUserName(state) {
    return `${state.user.first_name} ${state.user.last_name} ${state.user.last_name2}`
  },
  isVerified(state) {
    return state.user.activated
  },
  isRoleAllowed(state) {
    if (
      state.user &&
      state.user.role &&
      includes(['admin', 'operator', 'editor'], state.user.role)
    ) {
      return true
    }
    return false
  },
  isRoleWithoutOperator(state) {
    if (
      state.user &&
      state.user.role &&
      includes(['admin', 'editor'], state.user.role)
    ) {
      return true
    }
    return false
  },
  isOperator(state) {
    return state.user && state.user.role && state.user.role === 'operator'
  },
  isAdmin(state) {
    return state.user && state.user.role && state.user.role === 'admin'
  },
  isEditor(state) {
    return state.user && state.user.role && state.user.role === 'editor'
  },
  isSuperAdmin(state) {
    return state.user && state.user.role && state.user.role === 'superadmin'
  },
  getRole(state) {
    return state.user.role
  },
  getAuxRole(state) {
    return state.user.auxRole
  },
  getAppointmentsRole(state) {
    const availableModules = state.user.availableModules || []
    const apptModule = availableModules.find(
      (moduleItem) => moduleItem.module === 'appointments'
    )
    if (state.user && state.user.auxRole === 'officer' && apptModule)
      return apptModule.role
    else return null
  },
  getPaymentsRole(state) {
    const availableModules = state.user.availableModules || []
    const paymentsModule = availableModules.find(
      (moduleItem) => moduleItem.module === 'payments'
    )
    if (state.user && state.user.auxRole === 'officer' && paymentsModule)
      return paymentsModule.role
    else return null
  },
  getTypeMode(state) {
    return state.user.getTypeMode
  },
  getAvailableModules(state) {
    return state.user.availableModules
  },
  getInvolvedModules(state) {
    return state.user.involvedModules
  },
  getDependency(state) {
    return state.user.dependency
  },
  getUserEmail(state) {
    return state.user.email
  },
  token(state) {
    return state.token
  },
  getViloToken(state) {
    return state.vilo_token
  },
  usersIHavePermissions(state) {
    return state.user.usersIHavePermissions
      ? state.user.usersIHavePermissions
      : []
  },
  companiesIHavePermissions(state) {
    return state.user.companiesIHavePermissions
      ? state.user.companiesIHavePermissions
      : []
  },
  getProcedureCustomRolePermissions(state) {
    const availableModules = state.user.availableModules || []
    const proceduresModule = availableModules.find(
      (moduleItem) => moduleItem.submodule === 'procedures'
    )
    // Get permissions for the procedure
    if (
      state.user &&
      ['operator', 'admin', 'editor'].includes(state.user.role) &&
      proceduresModule
    ) {
      const permissions = proceduresModule.permissions
      if (permissions && permissions.length > 0) return permissions
      else return null
    } else {
      return null
    }
  },
  getInspectorsRole(state) {
    const availableModules = state.user.availableModules || []
    const inspectorsModule = availableModules.find(
      (moduleItem) => moduleItem.module === 'inspectors'
    )
    if (state.user && state.user.auxRole === 'officer' && inspectorsModule)
      return inspectorsModule.role
    else return null
  },
  getPrevDeliveryRole(state) {
    const availableModules = state.user.availableModules || []
    const preventiveDeliveryModule = availableModules.find(
      (moduleItem) => moduleItem.module === 'preventiveDelivery'
    )
    if (
      state.user &&
      state.user.auxRole === 'officer' &&
      preventiveDeliveryModule
    )
      return preventiveDeliveryModule.role
    else return null
  },
}
